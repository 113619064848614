
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import AccessLogExport from '@/components/fileProcessing/AccessLogExport.vue'
import BarcodeArchive from '@/components/fileProcessing/BarcodeArchive.vue'
import BarcodeExport from '@/components/fileProcessing/BarcodeExport.vue'
import LocationExport from '@/components/fileProcessing/LocationExport.vue'
import BarcodeAttachedFile from '@/components/fileProcessing/BarcodeAttachedFile.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import IconCSV from '@/components/svg/IconCSV.vue'
import IconExcel from '@/components/svg/IconExcel.vue'
import IconPDF from '@/components/svg/IconPDF.vue'
import IconRefresh from '@/components/svg/IconRefresh.vue'
import { SET_HAVE_NEW_EXPORT_RECORD } from '@/store/actions'
import { ClickOutside } from '@/utils/directives'
import { EExportType, EUserType } from 'smartbarcode-web-core/src/utils/enums/index'
import { mixins, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

@Options({
  directives: {
    clickOutside: ClickOutside,
  },
  components: {
    ItemNotFound,
    IconCSV,
    IconPDF,
    IconExcel,
    IconRefresh,
    BarcodeExport,
    AccessLogExport,
    LocationExport,
    BarcodeArchive,
    BarcodeAttachedFile,
  },
  emits: ['close'],
})
export default class FileProcessingPage extends mixins(DataTableMixin) {
  loading = false

  isShowClass = 'isShow'
  fileProcessingTabs = {
    barcodeData: 'barcodeData',
    archive: 'archive',
    accessLog: 'accessLog',
    location: 'location',
    file: 'file',
  }

  activeTabName = this.fileProcessingTabs.barcodeData
  oldActiveTabName = ''

  get isDevelopmentEnv(): boolean {
    return this.$store?.getters?.isDevelopmentEnv
  }

  get isEnableArchive(): boolean {
    return this.$store.getters.isEnableArchive
  }

  get haveNew() {
    return this.$store.state.barcodeExport?.[EExportType.BARCODE].haveNew ?? 0
  }

  get haveNewAccessLog() {
    return this.$store.state.barcodeExport?.[EExportType.ACCESS_LOG].haveNew ?? 0
  }

  get haveNewLocation() {
    return this.$store.state.barcodeExport?.[EExportType.LOCATION].haveNew ?? 0
  }

  get haveNewArchive() {
    return this.$store.state.barcodeExport?.[EExportType.ARCHIVE].haveNew ?? 0
  }

  get haveNewFile() {
    return this.$store.state.barcodeExport?.[EExportType.FILE].haveNew ?? 0
  }

  get isSetDisplayedBarcodeByClient() {
    return this.$store.state.profile?.organization?.setDisplayedBarcodeByClient || false
  }

  get loggedUser() {
    return this.$store.state.profile?.user || {}
  }

  get isLoggedAsEnterprise() {
    return this.loggedUser?.userType === EUserType.ENTERPRISE || false
  }

  async onReloadData() {
    const obj = {
      [this.fileProcessingTabs.barcodeData]: this.$refs.refBarcodeExport,
      [this.fileProcessingTabs.accessLog]: this.$refs.refAccessLogExport,
      [this.fileProcessingTabs.location]: this.$refs.refLocationExport,
      [this.fileProcessingTabs.archive]: this.$refs.refArchiveBarcode,
      [this.fileProcessingTabs.file]: this.$refs.refAttachedFileBarcode,
    }
    obj[this.activeTabName].currentPageChange(1)
  }

  get isHaveNew() {
    return [this.haveNew, this.haveNewAccessLog, this.haveNewLocation, this.haveNewArchive, this.haveNewFile].some(
      (v) => v > 0
    )
  }

  @Watch('haveNew')
  @Watch('haveNewAccessLog')
  @Watch('haveNewLocation')
  @Watch('haveNewArchive')
  @Watch('haveNewFile')
  applyAnimation() {
    const refFileProcressing = this.$refs.fileProcessingButtons
    if (this.isHaveNew) {
      refFileProcressing.style.right = '0rem'
      refFileProcressing.style.transition = 'right 0.05s'
      setTimeout(() => {
        refFileProcressing.style.right = '0.95rem'
        refFileProcressing.style.transition = 'right 0.1s'
        setTimeout(() => {
          refFileProcressing.style.right = '0.15rem'
          refFileProcressing.style.transition = 'right 0.08s'
          setTimeout(() => {
            refFileProcressing.style.right = '0.8rem'
            refFileProcressing.style.transition = 'right 0.07s'
            setTimeout(() => {
              refFileProcressing.style.right = '0.3rem'
              refFileProcressing.style.transition = 'right 0.06s'
              setTimeout(() => {
                refFileProcressing.style.right = '0.65rem'
                refFileProcressing.style.transition = 'right 0.05s'
                setTimeout(() => {
                  refFileProcressing.style.right = '0.4rem'
                  refFileProcressing.style.transition = 'right 0.035s'
                  setTimeout(() => {
                    refFileProcressing.style.right = '0.55rem'
                    refFileProcressing.style.transition = 'right 0.02s'
                    setTimeout(() => {
                      refFileProcressing.style.right = '0.5rem'
                      refFileProcressing.style.transition = 'right 0.01s'
                    }, 20)
                  }, 35)
                }, 50)
              }, 60)
            }, 70)
          }, 80)
        }, 100)
      }, 50)
    }
  }

  changeTab() {
    if (this.oldActiveTabName !== this.activeTabName) {
      this.onReloadData()
      this.oldActiveTabName = this.activeTabName
    }
  }

  hideFileProcessing() {
    const classList = this.$refs.fileProcessing.classList.value.split(' ')
    const indexIsShow = classList.indexOf(this.isShowClass) as number
    if (indexIsShow > -1) {
      classList.splice(indexIsShow, 1)
      this.$refs.fileProcessing.classList.value = [classList.join(' ')]
    }
  }

  async onLoadData() {
    const classList = this.$refs.fileProcessing.classList.value.split(' ')
    const indexIsShow = classList.indexOf(this.isShowClass) as number
    if (indexIsShow > -1) {
      classList.splice(indexIsShow, 1)
      this.$refs.fileProcessing.classList.value = [classList.join(' ')]
    } else {
      classList.push(this.isShowClass)
      this.$refs.fileProcessing.classList.value = [classList.join(' ')]
      if (this.haveNew === 0 && this.haveNewAccessLog > 0) {
        this.$store.commit(SET_HAVE_NEW_EXPORT_RECORD, { exportType: EExportType.ACCESS_LOG, isHaveNew: false })
        this.activeTabName = this.fileProcessingTabs.accessLog
      } else {
        this.$store.commit(SET_HAVE_NEW_EXPORT_RECORD, { exportType: EExportType.BARCODE, isHaveNew: false })
      }
      this.onReloadData()
    }
  }
}
