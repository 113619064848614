<template>
  <el-config-provider :locale="locale">
    <!-- Not found -->
    <div v-if="!!impersonatable" class="fixed flex items-center impersonatation-bar text-white">
      <div class="flex-grow flex justify-center ml-40">
        {{ $t('operating_as_admin_of_company', impersonatable) }}
      </div>
      <div class="flex-none mx-5 underline cursor-pointer" @click="returnOriginAcc">
        {{ $t('return_to_origin_account') }}
      </div>
    </div>
    <NotFound v-if="isNotFound" :item="isNotFoundItem" />

    <template v-else-if="isNoSidebarLayout">
      <router-view v-slot="{ Component }">
        <keep-alive :include="keepAliveComponent">
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </template>

    <template v-else>
      <div v-loading.fullscreen="!isAuth">
        <div class="smartbarcode-content" :class="isCollapseMenu ? 'app-menu-collapse' : ''">
          <!-- Sidebar menu -->
          <div class="smartbarcode-content__left bg-white">
            <AppSidebar v-if="shouldDisplayRestrictedContent" @collapse:update="updateCollapse" />
          </div>
          <!-- Main view -->
          <div
            :class="['main-content', !!impersonatable ? 'impersonatable-container' : '']"
            v-if="shouldDisplayRestrictedContent"
          >
            <router-view v-slot="{ Component }">
              <keep-alive :include="keepAliveComponent">
                <component :is="Component" />
              </keep-alive>
            </router-view>
          </div>
        </div>
        <FileProcessingPage v-if="isEnterprise || isSetDisplayedBarcodeByClient" />
      </div>

      <!-- Dialog -->
      <AppDialog />
    </template>
  </el-config-provider>
</template>

<script lang="ts">
import {
  CLEAR_PROFILE,
  FETCH_PROFILE,
  LOGOUT,
  RESET_BACK_TO_LOGIN,
  RESET_PAGE_NOT_FOUND,
  SET_PAGE_NOT_FOUND,
  UNIMPERSONATE,
} from '@/store/actions'
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

import AppDialog from '@/components/common/AppDialog.vue'
import AppSidebar from '@/components/common/AppSidebar.vue'
import NotFound from '@/components/common/NotFound.vue'
import FileProcessingPage from '@/components/fileProcessing/FileProcessingPage.vue'
import i18n from '@/i18n/index'
import en from 'element-plus/packages/locale/lang/en'
import ja from 'element-plus/packages/locale/lang/ja'
import zhCN from 'element-plus/packages/locale/lang/zh-cn'
import {
  ELanguageLocale,
  ESmartBarcodeMenu,
  ESmartBarcodeType,
  EUserRole,
  EUserType,
} from 'smartbarcode-web-core/src/utils/enums/index'
import { deleteCookie, getCookie, setCookie } from 'smartbarcode-web-core/src/utils/helpers'
import { IOrganization, IUser } from 'smartbarcode-web-core/src/utils/types/index'
import { LOCAL_STORAGE_ITEM } from './utils/constants'
import { addMinutes, isAfter } from 'date-fns'

@Options({
  components: {
    AppSidebar,
    AppDialog,
    NotFound,
    FileProcessingPage,
  },
  name: 'App',
})
export default class App extends Vue {
  isCollapseMenu = false
  pagesHaveNoSidebar = ['login', 'forgot-password', 'new-password', 'register']
  remindUpdateDate = (undefined as unknown) as Date
  isUpdateModalOpened = false

  updateCollapse(value: boolean) {
    this.isCollapseMenu = value
  }

  get isSetDisplayedBarcodeByClient() {
    return this.$store.state.profile?.organization?.setDisplayedBarcodeByClient || false
  }

  get locale() {
    const currentLocal = i18n.global.locale.split('-')[0]
    switch (currentLocal) {
      case ELanguageLocale.EN:
        return en
      case ELanguageLocale.JA:
        return ja
      case ELanguageLocale.ZH:
        return zhCN
    }
  }

  get impersonatable() {
    const ou = this.$store.state.profile.originUser
    const u: IUser = this.$store.state.profile.user
    const o: IOrganization = this.$store.state.profile.organization
    return ou ? { userFullName: u.fullName, orgName: o.name ?? '' } : undefined
  }

  async returnOriginAcc() {
    await this.$store.dispatch(UNIMPERSONATE).then(() => (window.location.href = '/'))
  }

  get hasSidebar(): boolean {
    return !this.pagesHaveNoSidebar.includes(this.$route.name)
  }

  get isNotFound() {
    return this.$store.state.isNotFound
  }

  get isNotFoundItem() {
    return this.$store.state.notFoundItem || 'item'
  }

  @Watch('$store.state.isBackToLogin')
  backToLoginChanged() {
    if (this.$store.state.isBackToLogin) {
      this.redirectLogin()
      this.$store.dispatch(RESET_BACK_TO_LOGIN)
    }
  }

  get isLoginPage() {
    return this.$route.name === 'login'
  }

  get isNoSidebarLayout() {
    return !this.hasSidebar
  }

  get shouldDisplayRestrictedContent(): boolean {
    return !this.hasSidebar || this.isAuth
  }

  get isAuth(): boolean {
    return this.$store.getters.isAuth
  }

  get isRestrictedPage(): boolean {
    return this.$route.meta.restricted
  }

  get keepAliveComponent(): string[] {
    return [
      ESmartBarcodeMenu.PROJECT,
      ESmartBarcodeMenu.CARRIER,
      ESmartBarcodeMenu.CLIENT,
      ESmartBarcodeMenu.LOCATION,
      ESmartBarcodeMenu.BARCODE_SEARCH,
    ]
  }

  redirectLogin() {
    window.location.href = '/login'
  }

  created() {
    document.addEventListener('onUpdateVersionNotification', (async () => {
      if (this.remindUpdateDate) {
        if (isAfter(new Date(), this.remindUpdateDate)) {
          await this.openUpdateDialog()
        }
      } else {
        await this.openUpdateDialog()
      }
    }) as EventListener)
  }

  confirmUpdateVersion() {
    window.location.reload()
  }

  remindUpdateLater() {
    this.remindUpdateDate = addMinutes(new Date(), 1)
  }

  closeUpdateDialog() {
    this.isUpdateModalOpened = false
  }

  async openUpdateDialog() {
    if (!this.isUpdateModalOpened) {
      this.isUpdateModalOpened = true
      await this.$confirm(this.$t('confirm_update'), this.$t('info'), {
        confirmButtonText: this.$t('update'),
        confirmButtonClass: 'danger',
        cancelButtonText: this.$t('later'),
        showClose: false,
      })
        .then(() => {
          this.confirmUpdateVersion()
        })
        .catch(() => this.remindUpdateLater())
      this.isUpdateModalOpened = false
    }
  }

  @Watch('isAuth')
  onAuthorizeChange() {
    if (this.isAuth) {
      const lastAuthorizedSystem = getCookie(LOCAL_STORAGE_ITEM.LAST_AUTHORIZED_SYSTEM)
      if (!lastAuthorizedSystem) {
        setCookie(LOCAL_STORAGE_ITEM.LAST_AUTHORIZED_SYSTEM, ESmartBarcodeType.PORTAL, 365)
      } else {
        if (getCookie(LOCAL_STORAGE_ITEM.LAST_AUTHORIZED_SYSTEM) === ESmartBarcodeType.MOBILE) {
          deleteCookie(LOCAL_STORAGE_ITEM.LAST_AUTHORIZED_SYSTEM)
          this.$store.commit(CLEAR_PROFILE)
          this.$store.dispatch(LOGOUT)
        }
      }
    } else {
      deleteCookie(LOCAL_STORAGE_ITEM.LAST_AUTHORIZED_SYSTEM)
    }
  }

  @Watch('$route.params')
  async onRouterChange() {
    if (!this.isLoginPage) {
      if (this.isRestrictedPage) {
        await this.$store.dispatch(FETCH_PROFILE)
      }
      if (!this.isAuth && this.isRestrictedPage) {
        this.redirectLogin()
        return
      }
    }
    if (this.$route.name === 'notFound') {
      this.$store.dispatch(SET_PAGE_NOT_FOUND)
      return
    } else {
      this.$store.dispatch(RESET_PAGE_NOT_FOUND)
    }

    if (this.loggedUser.roleType !== EUserRole.OWNER && this.$route.meta.menuKey === ESmartBarcodeMenu.ENTERPRISE) {
      this.$store.dispatch(SET_PAGE_NOT_FOUND)
    }

    this.checkEntitledPortalUIViews()
  }

  checkEntitledPortalUIViews() {
    if (
      this.$route?.meta?.menuKey &&
      this.$route?.meta?.restricted &&
      !this.entitledPortalUIViews.includes(this.$route.meta.menuKey)
    ) {
      this.$store.dispatch(SET_PAGE_NOT_FOUND)
    }
  }

  get isOwner() {
    return this.loggedUser?.roleType === EUserRole.OWNER
  }

  get entitledPortalUIViews() {
    return this.loggedUser?.entitledPortalUIViews || ([] as string[])
  }

  get loggedUser() {
    return this.$store.state.profile?.user || {}
  }

  get isEnterprise() {
    return this.loggedUser.userType === EUserType.ENTERPRISE
  }
}
</script>

<style lang="scss">
@import '~@/assets/css/mixins.scss';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap');

#app {
  font-family: 'Noto Sans JP', sans-serif, Helvetica, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100vh;
  background-color: $body-bg;
}

.smartbarcode-content {
  display: flex;
  width: 100%;
}

.main-content {
  overflow: auto;
  width: 100%;
  padding: 40px 40px 0px 40px;
}

.impersonatation-bar {
  height: 40px;
  width: 100%;
  background-color: rgba(208, 74, 74, 0.6);
  z-index: 51;
}

a:-webkit-any-link {
  text-decoration: none;
}
</style>
