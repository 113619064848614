
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import IconCSV from '@/components/svg/IconCSV.vue'
import IconExcel from '@/components/svg/IconExcel.vue'
import IconPDF from '@/components/svg/IconPDF.vue'
import IconRefresh from '@/components/svg/IconRefresh.vue'
import IconTSV from '@/components/svg/IconTSV.vue'
import IconUnknow from '@/components/svg/IconUnknow.vue'
import { LOAD_BARCODE_ATTACHED_FILE_LIST } from '@/store/actions'
import { BARCODE_EXPORT_FORMAT } from '@/utils/constants'
import { ClickOutside } from '@/utils/directives'
import errorHandler from '@/utils/errorHandler'
import { displayFileSize } from '@/utils/helpers'
import { EBarcodePublishStatus, EExportType } from 'smartbarcode-web-core/src/utils/enums/index'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IExportPaginationParams, ILocationResponse } from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'

type TBCExportFormatKey = keyof typeof BARCODE_EXPORT_FORMAT

@Options({
  directives: {
    clickOutside: ClickOutside,
  },
  components: { ItemNotFound, IconCSV, IconPDF, IconExcel, IconRefresh, IconTSV, IconUnknow },
  emits: ['close'],
  methods: { displayFileSize },
})
export default class BarcodeAttachedFile extends mixins(DataTableMixin) {
  loading = false
  dataSearch = {
    skip: 0,
    count: 10,
  } as IExportPaginationParams

  currentPageNum = 1

  fileUrl = ''

  get exportRecord() {
    return this.$store.state.barcodeExport?.[EExportType.FILE]
  }

  get isHaveData(): boolean {
    return this.exportRecord.data.length > 0
  }

  getFileFormat(fileName: string): string {
    if (fileName) {
      const splitedFileName = fileName.split('.')
      if (splitedFileName.length === 0) return ''
      return splitedFileName[fileName.split('.').length - 1]
    }
    return ''
  }

  isExistFile(data: ILocationResponse) {
    return data.status === EBarcodePublishStatus.COMPLETED && !isEmpty(data.fileUrl)
  }

  async onRefreshData() {
    this.loading = true
    await this.$store.dispatch(LOAD_BARCODE_ATTACHED_FILE_LIST, this.dataSearch).finally(() => (this.loading = false))
  }

  getIcon(icon: TBCExportFormatKey) {
    const iconMap: Record<TBCExportFormatKey, string> = {
      [BARCODE_EXPORT_FORMAT.csv]: 'IconCSV',
      [BARCODE_EXPORT_FORMAT.pdf]: 'IconPDF',
      [BARCODE_EXPORT_FORMAT.xlsx]: 'IconExcel',
      [BARCODE_EXPORT_FORMAT.tsv]: 'IconTSV',
      [BARCODE_EXPORT_FORMAT.zip]: 'IconUnknow',
      [BARCODE_EXPORT_FORMAT.unknown]: 'IconUnknow',
    }

    return iconMap[icon] || 'IconUnknow'
  }

  async currentPageChange(pageNum: number) {
    this.currentPageNum = pageNum
    const skipNum = (Number(pageNum) - 1) * Number(this.dataSearch.count)
    this.dataSearch.skip = skipNum
    await this.onRefreshData()
  }

  async downloadTemplate(filePath: string) {
    try {
      this.fileUrl = filePath
      setTimeout(() => this.$refs.btnDownloadTemplate.click(), 10)
    } catch (err) {
      errorHandler(err)
    }
  }
}
