/* eslint-disable no-useless-catch */
import {
  fetchDraftProject,
  fetchProject,
  fetchStaff,
  getActiveProjectsList,
  getProjectLocationsByProjectId,
  getProjectsList,
  getUserGroups,
} from '@/utils/api'
import {
  DEFAULT_PROJECT_INFORMATION_HAS_TRACKPOINT,
  DEFAULT_PROJECT_INFORMATION_NO_TRACKPOINT,
  PROJECT_NEW_KEY_ID,
} from '@/utils/constants'
import { formatBarcodeType, formatInitPermissionGroup } from '@/utils/helpers'
import cloneDeep from 'lodash/cloneDeep'
import {
  IBarcodeDefinitionType,
  ICreateUserGroup,
  ICreateUserGroupForm,
  IProject,
  IProjectDetail,
  IProjectDraft,
  IProjectLocation,
  IProjectsList,
  IProjectUserGroup,
  IStaff,
  ITrackingPermissions,
  ITrackpoint,
} from 'smartbarcode-web-core/src/utils/types/index'
import {
  CLEAR_ALL,
  CLEAR_DETAIL_PROJECT,
  CLEAR_LOADING,
  CLEAR_LOAD_PROJECTS_LIST,
  FETCH_PROJECT,
  FETCH_USER_GROUP_STAFF,
  INIT_PROJECT_DETAIL,
  LOAD_ACTIVE_PROJECTS_LIST,
  LOAD_CURRENT_USER_PROJECTS_LIST,
  LOAD_PROJECTS_LIST,
  SAVE_PROJECT,
  SET_LOADING,
  SET_PROJECT_READ_ONLY,
  UPDATE_BARCODE_TYPES,
  UPDATE_ORIGIN_PROJECT_DETAIL,
  UPDATE_TRACKING_POINTS,
} from './actions'

type TProjectStates = {
  isLoaded: boolean
  projects: IProject[]
  originalProjects: IProject[] // filted visible projects
  activeProjects: IProject[] // all projects
  currentUserProjects: IProject[]
  projectDetail: IProjectDetail
  originalProjectDetail: IProjectDetail
  originalPermissionGroupProject: ICreateUserGroupForm[]
  loading: boolean
  isReadonly: boolean
  userGroups: ICreateUserGroupForm[]
  locations: IProjectLocation[]
  staffs: IStaff[]
}

type IResponse = {
  commit: Function
  dispatch: Function
  state: TProjectStates
}

function clearState(state: TProjectStates) {
  state.isLoaded = false
  state.projects = []
  state.originalProjects = []
  state.activeProjects = []
  state.currentUserProjects = []
  state.projectDetail = cloneDeep(DEFAULT_PROJECT_INFORMATION_NO_TRACKPOINT)
  state.originalProjectDetail = cloneDeep(DEFAULT_PROJECT_INFORMATION_NO_TRACKPOINT)
  state.originalPermissionGroupProject = []
  state.isReadonly = false
  state.userGroups = []
  state.locations = []
  state.staffs = []
}

function clearOnlyDetailState(state: TProjectStates) {
  state.projectDetail = cloneDeep(DEFAULT_PROJECT_INFORMATION_NO_TRACKPOINT)
  state.originalProjectDetail = cloneDeep(DEFAULT_PROJECT_INFORMATION_NO_TRACKPOINT)
  state.originalPermissionGroupProject = []
  state.isReadonly = false
}

export default {
  state: {
    isLoaded: false,
    projects: [],
    originalProjects: [],
    activeProjects: [],
    projectDetail: cloneDeep(DEFAULT_PROJECT_INFORMATION_NO_TRACKPOINT),
    originalProjectDetail: cloneDeep(DEFAULT_PROJECT_INFORMATION_NO_TRACKPOINT),
    originalPermissionGroupProject: [],
    loading: false,
    isReadonly: false,
    userGroups: [] as ICreateUserGroup[],
    locations: [] as IProjectLocation[],
    staffs: [] as IStaff[],
  },
  getters: {
    getProjectsList: (state: IProjectsList) => {
      return state.projects
    },
    getProjectsTrackingPoints: (state: TProjectStates) => {
      return state.projectDetail.trackPoints
    },
    getProjectReadonly: (state: TProjectStates) => {
      return state.isReadonly
    },
  },
  mutations: {
    [LOAD_PROJECTS_LIST]: (
      state: TProjectStates,
      {
        projects,
        originalProjects,
      }: {
        projects: IProject[]
        originalProjects: IProject[]
      }
    ) => {
      state.isLoaded = true
      state.projects = projects
      state.originalProjects = originalProjects
    },
    [LOAD_ACTIVE_PROJECTS_LIST]: (
      state: TProjectStates,
      {
        projects,
      }: {
        projects: IProject[]
      }
    ) => {
      state.activeProjects = projects
    },
    [LOAD_CURRENT_USER_PROJECTS_LIST]: (
      state: TProjectStates,
      {
        projects,
      }: {
        projects: IProject[]
      }
    ) => {
      state.currentUserProjects = projects
    },
    [SET_LOADING]: (state: TProjectStates) => {
      state.loading = true
    },
    [SET_PROJECT_READ_ONLY]: (state: TProjectStates, payload: boolean) => {
      state.isReadonly = payload
    },
    [CLEAR_LOADING]: (state: TProjectStates) => {
      state.loading = false
    },
    [SAVE_PROJECT]: (
      state: TProjectStates,
      payload?: {
        project: IProjectDetail
        locations: IProjectLocation[]
      }
    ) => {
      if (payload) {
        state.projectDetail = { ...state.projectDetail, ...payload }
        state.originalPermissionGroupProject = cloneDeep(state.projectDetail.permissionGroup) || []
        state.locations = cloneDeep(payload.locations) ?? []
      }
    },
    [UPDATE_ORIGIN_PROJECT_DETAIL]: (state: TProjectStates, payload?: IProjectDetail) => {
      if (payload) {
        state.originalProjectDetail = cloneDeep(payload)
      }
    },
    [UPDATE_TRACKING_POINTS]: (state: TProjectStates, payload: Record<string, ITrackpoint>) => {
      state.projectDetail = { ...state.projectDetail, trackPoints: payload }
    },
    [UPDATE_BARCODE_TYPES]: (state: TProjectStates, payload: Record<string, IBarcodeDefinitionType>) => {
      state.projectDetail = { ...state.projectDetail, barcodeTypes: payload }
    },
    [CLEAR_DETAIL_PROJECT]: (state: TProjectStates) => {
      clearOnlyDetailState(state)
    },
    [CLEAR_ALL]: (state: TProjectStates) => {
      clearState(state)
    },
    [INIT_PROJECT_DETAIL]: (state: TProjectStates) => {
      state.projectDetail = cloneDeep(DEFAULT_PROJECT_INFORMATION_HAS_TRACKPOINT)
    },
    [CLEAR_LOAD_PROJECTS_LIST]: (state: TProjectStates) => {
      clearState(state)
    },
    [FETCH_USER_GROUP_STAFF]: (
      state: TProjectStates,
      payload: {
        userGroups: ICreateUserGroupForm[]
        staffs: IStaff[]
      }
    ) => {
      if (payload) {
        state.userGroups = payload.userGroups
        state.staffs = payload.staffs
      }
    },
  },
  actions: {
    [LOAD_PROJECTS_LIST]: async ({ commit }: { commit: Function }) => {
      try {
        commit(SET_LOADING)
        const { projects, projectDrafts }: IProjectsList = await getProjectsList()
        const originalProjects = projects?.concat(projectDrafts ?? [])
        const projectCodeAdded = [] as string[]
        if (projectDrafts && projects) {
          projects.forEach((item, index) => {
            if (!projectCodeAdded.includes(item.code || '')) {
              const haveDrafts = projectDrafts.find(
                (project) => item.code === project.code && item.version === project.version
              )
              if (haveDrafts) {
                projectCodeAdded.push(item.code || '')
                projects[index] = haveDrafts
              }
            }
          })

          projectDrafts
            .filter((draft) => !(draft?.version && draft.version !== 0))
            .forEach((draft) => {
              projects.push(draft)
            })
        }
        commit(LOAD_PROJECTS_LIST, { projects, originalProjects })
      } catch (err) {
        throw err
      } finally {
        commit(CLEAR_LOADING)
      }
    },
    [LOAD_ACTIVE_PROJECTS_LIST]: async ({ commit }: { commit: Function }) => {
      try {
        commit(SET_LOADING)
        const { projects }: IProjectsList = await getActiveProjectsList(false)
        commit(LOAD_ACTIVE_PROJECTS_LIST, { projects })
      } catch (err) {
        throw err
      } finally {
        commit(CLEAR_LOADING)
      }
    },
    [LOAD_CURRENT_USER_PROJECTS_LIST]: async ({ commit }: { commit: Function }) => {
      try {
        commit(SET_LOADING)
        const { projects }: IProjectsList = await getActiveProjectsList(true)
        commit(LOAD_CURRENT_USER_PROJECTS_LIST, { projects })
      } catch (err) {
        throw err
      } finally {
        commit(CLEAR_LOADING)
      }
    },
    [FETCH_PROJECT]: async ({ state, commit }: IResponse, payload: Record<string, string>) => {
      try {
        const { projectId, projectCode, version, isDraft } = payload
        commit(SET_LOADING)
        if (isDraft) {
          const project = (await fetchDraftProject(projectId)) as IProjectDraft
          const { trackPoints, barcodeTypes, userGroups, locations, ...mainInfo } = project
          mainInfo.isDraft = true
          const permissionGroup = [] as ICreateUserGroupForm[]
          if (userGroups) {
            userGroups.forEach((val: IProjectUserGroup) => {
              const { projectPermission, ...restData } = val
              const newUserGroup = restData as ICreateUserGroupForm
              newUserGroup.projectPermissions = {
                [PROJECT_NEW_KEY_ID as string]: projectPermission,
              } as ITrackingPermissions

              permissionGroup.push(newUserGroup)
            })
          }

          for (const i in barcodeTypes) {
            barcodeTypes[i] = formatBarcodeType(barcodeTypes[i])
          }

          const projectDetail = {
            mainInfo,
            trackPoints,
            barcodeTypes,
            permissionGroup: formatInitPermissionGroup(mainInfo, permissionGroup),
            locations: (locations ?? []).map(({ name, ...others }) => ({
              ...others,
              locationName: name,
            })),
          } as IProjectDetail
          commit(SAVE_PROJECT, projectDetail)
        } else {
          const project = (await fetchProject(projectCode, version)) as IProject
          const { trackPoints, barcodeTypes, ...mainInfo } = project
          for (const i in barcodeTypes) {
            barcodeTypes[i] = formatBarcodeType(barcodeTypes[i])
          }

          const userGroups = cloneDeep(state.userGroups)
          const permissionGroup = userGroups.filter((item) => !!item?.projectPermissions?.[mainInfo.id || ''])
          permissionGroup.map((group: ICreateUserGroupForm) => {
            if (group.userIds === undefined) {
              group.userIds = [] as string[]
            }
            const staffsInGroup = state.staffs.filter(
              (item: IStaff) => item.userGroupIds && item.userGroupIds.includes(group.id as string)
            )
            staffsInGroup.forEach((item: IStaff) => {
              if (group.userIds !== undefined) {
                group.userIds.push(item.id as string)
              }
            })
          })

          // project-locations
          const locations = await getProjectLocationsByProjectId(project?.id ?? '')

          const projectDetail = {
            mainInfo,
            trackPoints,
            barcodeTypes,
            permissionGroup: formatInitPermissionGroup(mainInfo, permissionGroup),
            locations,
          } as IProjectDetail
          commit(SAVE_PROJECT, projectDetail)
        }
      } catch (err) {
        throw err
      } finally {
        commit(CLEAR_LOADING)
      }
    },
    [FETCH_USER_GROUP_STAFF]: async ({ commit }: IResponse) => {
      try {
        const [userGroups, staffs] = await Promise.all([getUserGroups(), fetchStaff()])
        commit(FETCH_USER_GROUP_STAFF, { userGroups, staffs })
      } catch (err) {
        commit(FETCH_USER_GROUP_STAFF, { userGroups: [], staffs: [] })
        throw err
      }
    },
  },
}
