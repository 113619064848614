
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import IconNotFound from '@/components/svg/IconNotFound.vue'

@Options({
  components: { IconNotFound },
  name: 'ItemNotFound',
})
export default class ItemNotFound extends Vue {
  @Prop({ type: String }) readonly content!: string
}
